$(document).on("turbolinks:load", common_events);

function common_events() {

    $('body').on('change', '#insurance_insurance_type', function(){
        let value = $(this).val();
        let url = $(this).data('url');
        $.ajax({
            url: url,
            dataType: 'script',
            type: 'GET',
            data: {
                insurance_type: value
            }
        })
    })

    $('body').on('click', '#add-provider-insurance', function(){
        let rut = $('#rut-new-insurance').val();
        let name = $('#name-new-insurance').val();
        const url = '/create_provider_insurance'
        $.ajax({
            url: url,
            dataType: 'script',
            method: 'post',
            data: {
                rut: rut,
                name: name
            }
        })
    })

    $('body').on('click', '.edit-insurance', function(){
        let target = $(this).data('target');
        $(target).toggleClass('disabled');
    })
}