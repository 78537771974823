import consumer from "./consumer"

function initImportPortfolioMonitor(filename){
  $alerts.info({ message: 'Estamos procesando el archivo, te informaremos cuando lo tengamos listo ' + filename }, 3000)
  $('.loader').fadeOut();
  $('#button-add-portfolio-monitor').val('');
}

consumer.subscriptions.create("PortfolioMonitorChannel", {
  received(data) {
    if (data.action === 'init_import_portfolio_monitor') initImportPortfolioMonitor(data.filename)
  }
});