$( document ).on('turbolinks:load', common_events)

function common_events(){
  $('body').on('click', '#show-installment-payments', function() {
    if($("#installment-credit-line-payments").hasClass('d-none')){
      $("#installment-credit-line-payments").removeClass('d-none')
    } else {
      $("#installment-credit-line-payments").addClass('d-none')
    }
    $("#installment-credit-line-payment").addClass('d-none')
  })

  $('body').on('click','#additional-charge-display-panel-close', function() {
    $('#additional-charge-display-panel').removeClass('w-30')
    $('#additional-charge-display-panel').addClass('d-none')
    $('#additional-charge-table-panel').addClass('w-100')
    $('#installment-additional-charge-pay').addClass('d-none')
  })
}