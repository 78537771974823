$( document ).on('turbolinks:load', common_events)

function common_events() {
    var count = 0;
    $('body').on('click', '#submit-banks', function(){
        let company = $(this).data('company');
        let valInputBank = $('#bank-input').val();
        let valInputOwner = $('#owner-input').val();
        let valInputType = $('#type-input').val();
        let valInputOwnerRut = $('#owner-rut-input').val();
        let valInputAccountNumber = $('#account-number-input').val();
        addInputsBanks(company, valInputBank, valInputOwner, valInputType, valInputOwnerRut, valInputAccountNumber);
        $('.inputs-banks').val('');
        $('.loader').fadeOut(500);
        count++;
    });

    $('.cancel').on('click', function(){
      cleanModalInput();
    })

    function cleanModalInput() { 
      $('.inputs-banks').val('');
      $('#container-bank-modal').empty();
    }

    $('body').on('click', '.submit-banks', function(){
        let url = $(this).data('url');
        // readDataUrlAjax(url);
    });

    $('body').on('click', '.submit-button', function(){
        let target = $(this).data('target');
        $(target).click();
    });

    function readDataUrlAjax(url){
        // poner un loaded
        $.ajax({
          type: "GET",
          url: url,
          dataType: "script",
          success: function () {
            // quitar el loaded
          },
        });
      };

    function addInputsBanks(company, valInputBank, valInputOwner, valInputType, valInputOwnerRut, valInputAccountNumber){
        if (!valInputBank || !valInputOwner || !valInputType || !valInputOwnerRut || !valInputAccountNumber) {
            $modals.info({message: 'Debes completar todos los campos', title: 'Error'});
            return;
        }
        let nameAttribute = "bank_account"
        let inputBank = `<td><input class="form-control form-control-banks disabled-bank" value="${valInputBank}" type="text" name="${nameAttribute}[][bank]" id="bank_bank"></td>`;
        let inputOwner = `<td><input class="form-control form-control-banks disabled-bank" value="${valInputOwner}" type="text" name="${nameAttribute}[][owner]" id="bank_owner"></td>`;
        let inputType = `<td>
                                    <input class="form-control form-control-banks disabled-bank" value="${valInputType}" type="text" name="${nameAttribute}[][bank_type]" id="bank_bank_type">
                                    <input class="form-control form-control-banks disabled-bank hide" value="${valInputType}" type="text" name="${nameAttribute}[][account_type]" id="bank_account_type">
                                </td>`;
        let inputOwnerRut = `<td><input class="form-control form-control-banks disabled-bank rut-input" value="${valInputOwnerRut}" type="text" name="${nameAttribute}[][owner_rut]" id="bank_owner_rut"></td>`;
        let inputAccountNumber = `<td><input class="form-control form-control-banks disabled-bank" value="${valInputAccountNumber}" type="text" name="${nameAttribute}[][account_number]" id="bank_account_number"></td>`;
        let inputCompany = `<input type="hidden" value="${company}" name="${nameAttribute}[][company_id]" id="bank_company_id">`;
        let tdCheck = `<td><input type="radio" name="${nameAttribute}[is_validated]" id="bank_is_validated"></td>`;
        let tdPredeterminated = `<td></td>`;
        let container = $('#container-bank-modal');

        $('<tr>' + inputBank + inputOwner + inputType + inputOwnerRut + inputAccountNumber + '</tr>' + inputCompany).hide().prependTo(container).fadeIn(1000);
    }

    // Checkbox

    $('body').on('change', '.checkbox-predeterminated', function () { 
      let url = $(this).data('url');
      $.ajax({ method: "POST", url: url, dataType: "script"});
    })
}