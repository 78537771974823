
$(document).on('turbolinks:load', () => {
  $('body').on('click', '#edit-mode-switch', function() {
    if( $(this).is(':checked') ){
      $(".installment-editable-field").removeClass('disabled-input')
      $('.installment-editable-btn').removeClass('hide')
      $('.installment-paid-at-form').removeClass('hidden')
      $('.installment-paid-at').addClass('hidden')
    } else {
      $(".installment-editable-field").addClass('disabled-input')
      $(".installment-editable-btn").addClass('hide')
      $('.installment-paid-at-form').addClass('hidden')
      $('.installment-paid-at').removeClass('hidden')
    }
  })

  $('.show-invoice-operation').on("click", function(){
    let close_icon = $('#close-document-button > svg > path')
    close_icon.attr("fill",'#0055B8')
    let title = 'Factura del vehículo #' + $(this).data('id')
    let url = $(this).data('url')
    let $modal = $('#show-invoice')
    let url_archive = $(this).data('archive-url')
    if(!window.mobileAndTabletCheck()){
      $modal.find('#invoice-display-div').html($('<embed></embed>', {
        width: "100%", height: "100vh",
        src : url,
        id: "document-opener"
      }))
    } else {
      $modal.find('#invoice-display-div').html($('<iframe />', {
        width: "100%", height: "100vh",
        src : url,
        id: "document-opener"
      }))
    }
    $modal.modal('toggle');
    $modal.find('.modal-title').html(title)
    $modal.find('.link-download-archives').attr('data-url', url)
    if (url_archive){
      $modal.find('.loader-delete').removeClass('d-none').attr('href', url_archive)
    }
  });

  $('#download-installments-button').on("click", function(e){
    const query = $('#filter-installments-input').val()
    const insurance_number = $('#insurance_number').val()
    const expire_date = $('#expire_date').val()
    $('#hidden-query').val(query)
    $('#hidden-insurance-number').val(insurance_number)
    $('#hidden-expire-date').val(expire_date)
    $('#installments-download-form').trigger('submit.rails')
  })
})