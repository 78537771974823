$(document).on("turbolinks:load", common_events);

function common_events() {

  var getCurrentTabActive = "partners-tab";

  const validateCompanyProperty = function (company, property) {
    if (company[property] !== null) {
      $(`.${property}-input-company`).addClass("disabled-input");
      $(`.${property}-input-company`).val(company[property]);

      if (property === "company_type") {
        $(`#partner_associated_company_company_type_${company[property]}`).prop(
          "checked",
          true
        );
        toggleNaturalPersonForm(
          $(`#partner_associated_company_company_type_${company[property]}`)
        );
      }
    } else {
      $(`.${property}-input-company`).val("");
      $(`.${property}-input-company`).removeClass("disabled-input");
    }
  };

  const validateCompany = function (company) {
    if (company) {
      $(".form-group-company").removeClass("hide");
      Object.keys(company).forEach((property) => {
        validateCompanyProperty(company, property);
      });
    } else {
      $("#name-input-company").val("");
      $(".form-group-company").removeClass("hide");
      $(".form-control-company").removeClass("disabled-input");
    }
  };

  $("body").on(
    "change",
    ".rut-input-ldt, #rut-input-company-debtor",
    function (event) {
      if ($(this).val().length > 0) {
        $(".loader").fadeIn(100);
        var rut_company = $(this).val();
        var form_id = $(this).parents("form").attr("id") || $(this).data('form');
        if (rut_company.length) {
          event.preventDefault();
          $.ajax({
            type: "GET",
            data: {
              rut: rut_company,
              form_id: form_id,
            },
            url: "/company_info",
            dataType: "script",
            success: function () {
              $(".loader").fadeOut(500);
            },
            error: function () {
              $(".loader").fadeOut(500);
            },
          });
        }
      }
    }
  );

  // Esta funcion injecta la url al hacer click en el boton desplegable de cada socio
  $("body").on("click", ".collapse-toggle-partners", function () {
    const $toggle = $(this);
    const isLoaded = $toggle.data("loaded");
    const url = $toggle.data("url");
    const $collapse = $toggle.parents("tr").next("tr").first();
    const partnerType = $toggle.data("partner-type");
    const companyId = $toggle.data("company-id");

    if (!isLoaded) {
      $(".loader").fadeIn();
      const targetId = Date.now();
      const collapseId = `collapse-${partnerType}-${targetId}`;
      const toggleId = `toggle-collapse-${partnerType}-${targetId}`;
      $collapse.attr("id", collapseId);
      $toggle.attr("id", toggleId).attr("href", `#${collapseId}`);

      $.ajax({
        type: "GET",
        url: `${url}?target_id=${targetId}&company_id=${companyId}&partner_type=${partnerType}`,
        dataType: "script",
        success: () => $(".loader").fadeOut(),
      });
    }

    $(this).find("svg").toggleClass("icon-down");
  });

  $("#modalpartners").on("hide.bs.modal", function (event) {
    $(".pill-radio-button-partner").removeClass("d-none");
    $("#partner_participation_percentage").val("");
    $("#partner_participation_percentage").removeClass("disabled");
  });

  // Cambia el check en la pestaña de partners

  $("body").on("change", "#company_company_type", function () {
    let valueOfInputCompanyType = $(this).val();
    changeCheckInPartner(valueOfInputCompanyType);
  });

  function changeCheckInPartner(value) {
    if (value === "natural_person") {
      $("#icon-unfinished-in-partner").addClass("d-none");
      $("#icon-check-in-partner").removeClass("d-none");
      $(".tab-content-partners").addClass("d-none");
      $(".profile-tab").click();
    } else {
      $("#icon-unfinished-in-partner").removeClass("d-none");
      $("#icon-check-in-partner").addClass("d-none");
      $(".tab-content-partners").removeClass("d-none");
      $(".tab-content-partners").click();
    }
  }

  function eirlCompanyType(value) {
    if (value === "eirl") {
      $("#partner_participation_percentage").addClass("disabled");
      $("#partner_participation_percentage").val("100");
    }
  }

  // carga el loader cada vez que se envía un form

  $('body').on('submit', 'form', function () {
    const noLoader = $(this).data('no-loader')

    if (noLoader) return;

    if ($(this).hasClass('deal-form')){
      // $('.loader').fadeOut();
    }else{

      $('.loader').fadeIn(100);

    }
  });
  // agrega la url al modal-form-with-id "Es posible que esta funcion no la esté usando, hay que depurar"
  $("body").on("click", ".open-modal-form-with-id", addURL);

  // Ejecuta si el socio es persona natural
  $("body").on("click", ".btn-radio", function (e) {
    let radio = $(e.target).prev();
    radio.prop("checked", true);
    toggleNaturalPersonForm(radio);
  });

    // botones de edicion de partners
    $('body').on('click','.edit-partners', function (){
      $(this).parent().find('.edit-partners').toggleClass('d-none')
      $('.edit-button').toggleClass("hide");
      $('.collapse-toggle-partners').toggleClass('hide')
    });

    $('body').on('click', '.nav-link-secondary', function () {
      $('.edit-button').addClass("hide");
      let parent = $($(this).attr('href')).find('.edit-partners').first().parent();
      parent.children().first().removeClass("d-none");
      parent.children().last().addClass("d-none");
    })
  
    function resetEditButtonPartners(){
  
      var current_tab_active = getCurrentTabActive;
      $('.edit-button').addClass("hide");
      $('.add-button').removeClass("hide");
      $('.collapse-toggle-partners').removeClass("hide");
    
      if ($('.can-add-partner-to-eirl').val() == "true" && current_tab_active == "partners-tab"){
        $('.button-add-partner').removeClass("hide");
        $('.button-add-partner').removeClass("d-none");
      }else if ($('.can-add-partner-to-eirl').val() == "false" && current_tab_active == "partners-tab"){
        $('.button-add-partner').addClass("hide");
        $('.button-add-partner').addClass("d-none");
      }else if (current_tab_active != "partners-tab"){
        $('.button-add-partner').removeClass("hide");
        $('.button-add-partner').removeClass("d-none");
      }
      
      $('#edit-partners-eirl').html("Editar Socios");
      $('#edit-partners').html("Editar Socios");
      $('.toggle-edit-btn').prop("checked", false)
    }
  
    // botones de edicion de partners para eirl
    $('body').on('click','#edit-partners-eirl', function (){
  
      var current_tab_active = getCurrentTabActive;
      if (current_tab_active == "partners-tab"){
  
        if ($('.can-add-partner-to-eirl').val() == "false"){
          $('.button-add-partner').addClass("hide");
        }else if (current_tab_active != "partners-tab"){
          $('.button-add-partner').removeClass("hide");
        }
  
        $('.edit-button').toggleClass("hide");
        $('.collapse-toggle-partners').toggleClass('hide');
        $(this).html($('.toggle-edit-btn').prop("checked") ? 'Editar Socios' : 'Finalizar');
        $('.toggle-edit-btn').prop("checked", !$('.toggle-edit-btn').prop("checked"));
  
      }else{
        $('.button-add-partner').toggleClass("hide");
        $('.edit-button').toggleClass("hide");
        $('.collapse-toggle-partners').toggleClass('hide')
        $(this).html($('.button-add-partner').hasClass("hide") ? 'Finalizar' : 'Editar Socios');
      }
    });
  
    $('.partner-tab').on("click", function(){
      getCurrentTabActive = $(this).attr('id')
      console.log("aaaaa")
      console.log($('#is-eirl-company').val())
      $('#is-eirl-company').val() == "true" ? resetEditButtonPartners() : ""
    });


  // Define si aparece o no regimen
  $("body").on(
    "change",
    "#partner_associated_company_civil_status",
    function () {
      let optionValue = $(this).val();
      if (optionValue === "Casado/a") {
        $("#marital_regime").removeClass("d-none hide");
      } else {
        $("#partner_associated_company_marital_regime").val("");
        $("#marital_regime").addClass("d-none hide");
      }
    }
  );

  $("body").on("click", ".tab-partners", function () {
    if ($(this).hasClass("no-edit")) {
      $("#edit-partners").addClass("d-none");
    } else {
      $("#edit-partners").removeClass("d-none");
    }
  });


  $('body').on('change', '#partner_associated_company_rut', function() {
    const url = $(this).data('url')
    const $form = $('#partner-form')
    const query = $form.serialize()

    $.ajax({
      url: `${url}?${query}`,
      dataType: "script"
    });
  })
}

function toggleNaturalPersonForm(radio) {
  if (radio.val() === "natural_person") {
    $(".marital-regime-input-company").parent().removeClass("hide");
    $(".civil-status-input").parent().removeClass("hide");
  } else {
    $("#marital_regime").addClass("d-none hide");
    $(".marital-regime-input-company").val("");
    $(".civil-status-input").val("");
    $(".civil-status-input").parent().addClass("hide");
  }
}

// Esta funcion rellena el value de hidden-field en el formulario de partners
function addURL() {
  $(".error").html("");
  $(".is-invalid").removeClass("is-invalid");
  $(".form-control-partners-modal").val("");
  let url = $(this).data("url");
  let target;

  if ($(this).is("button")) {
    target = $(this).data("target");
  }

  if ($(this).is("a")) {
    target = $(this).attr("href");
  }
  if (url !== undefined && target !== undefined) {
    $(target).find("form").attr("action", url);
  }
}
