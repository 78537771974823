$(document).on("turbolinks:load", common_events);

function common_events() {
    $('body').on('change', '.calculate-amount', function () {
        let percentage_rate, dateAdditionalCharge, dateInstallment, amount, days, total, numberDays, deposits_sum;
        percentage_rate = parseFloat($('#additional_charge_percentage_rate').val() || 1);
        dateAdditionalCharge = $('#additional_charge_paid_at').val();
        dateInstallment = $('#installment_expire_date').val();
        numberDays = getDaysBetweenDates(dateInstallment, dateAdditionalCharge);
        $('#amount-installment').attr('data-days', numberDays);
        amount = parseFloat($('#amount-installment').data('amount'));
        days = parseInt(numberDays);

        if (days < 1 || isNaN(days)) {
            $alerts.danger({ message: 'La fecha de pago no puede ser menor o igual a la fecha vencimiento de la cuota' });
        } else {
            total = ((amount * ((percentage_rate/100) / 30)) * days);
            total = total.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
            $('#amount-charge').val(total);
        }
    })

    function getDaysBetweenDates(date1, date2) {
        const [day1, month1, year1] = date1.split('-').map(Number);
        const [day2, month2, year2] = date2.split('-').map(Number);

        const firstDate = new Date(year1, month1 - 1, day1);
        const secondDate = new Date(year2, month2 - 1, day2);

        const differenceInTime = secondDate - firstDate;

        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        return differenceInDays;
    }

    $('body').on('click', '.close-form', function () {
        let parent = $(this).parent();
        parent.html("");
        if(parent.attr('id') == 'installment-additional-charge-pay'){
            $('#additional-charge-table-panel').removeClass('w-70').addClass('w-100')
            $('#additional-charge-display-panel').removeClass('w-70').addClass('d-none')
        }
        $('.button-extensions').removeClass('selected');
    })

    $('body').on('click', '.button-extensions', function () {
        $(this).parent().find('.button-extensions').removeClass('selected')
        $(this).addClass('selected')
    })
}