const Awesomplete = require('awesomplete');
const rutFormat = require('../utils/rutFormat');


function awesomizeInput(input, list) {
   return new Awesomplete(input, {
      list: list,
      data: function (item, input) {
         return { label: item, value: item.toString().toLowerCase().replaceAll(' ', '') };
      }, 
      filter: function(item, input) {
         if (item.label === 'SERVICIOS EMPRESARIALES ORSAN S.A.') {
            console.log(item)
         }
         return item.value.includes(input.toLowerCase().replaceAll(' ', ''))
      },
      replace: function(item) {
         this.input.value = item.label;
         this.input.dataset.result = item.toLowerCase()
      }
   });
}

$( document ).on('turbolinks:load', () => {
   const allInputs = document.querySelectorAll('input.awesomplete')
   const allAwesomeInputs = []
   allInputs.forEach(input => allAwesomeInputs.push(awesomizeInput(input, [])))

   $('body').on('keydown', 'input.awesomplete', function(e) {
      const $input = $(this).is('input') ? $(this) : $(this).find('input');
      const value = $input.val()
      let awesomizedInput = allAwesomeInputs.find(awsInput => awsInput.input === $input[0])

      if (!awesomizedInput) {
         awesomizedInput = awesomizeInput($input[0], [])
         allAwesomeInputs.push(awesomizedInput)
         $input.trigger('focus')
      }

      if (awesomizedInput) {
         if (value.length === 3) {
            const path = $input.data('awesome-path')
            const url = $input.data('awesome-url')
            const keys = $input.data('awesome-keys')
            const fileName = $input.data('file-name')
            const section = $input.data('section')
            const installmentable_type = $input.data('installmentable-type')
   
            console.log("filename: ", fileName)

            $.ajax({
            type:'GET',
            url: url || `/awesomeplete/${path}`,
            data: {
               section: section,
               keys,
               query: value,
               fileName,
               no_limit: true,
               installmentable_type
            },
            dataType: 'json',
            success:function(data){
               awesomizedInput.list = data.list
               awesomizedInput.evaluate();
            }, 
            error: function (error) {
               console.error("Error:", error);
            },
            })
         } else if (value.length < 3) {
            awesomizedInput.list = []
            awesomizedInput.evaluate();
         }
      }
   })
  
    $('body').on('click', 'li[role="option"]', function(){
      $('input.awesomplete').removeClass('rut-client')
    })
  
    $('body').on('keyup', '.rut-client', function(e) {
      rutFormat($(this))
    })

   function listSelect2(list = {}, id_selector){
   }

   function emptyListSelect2(){}
})