const formatNumber = require('./utils/format_number');

$(document).on('turbolinks:load', () => {

  $('body').on('change', '.approval_status_type_input', function (e) {
    const value = $(this).val()
    const disableInputs = String($(this).data('disable-inputs')) == 'true'
    const $subLineAmountInputs = $('.sub-line-amount')
    const $lineFullAmount = $('#line_full_amount')
    const $lineExpireDate = $('#line_expired_at')
    const $lineUndisabled = $('.line_undisabled')
    const lineFullAmountPreviousValue = $lineFullAmount.data('previous-value')
    const lineExpireDatePreviousValue = $lineExpireDate.data('previous-value')

    if (value == 'returned' || !disableInputs) {
      $subLineAmountInputs.removeAttr('disabled')
      $lineFullAmount.removeAttr('disabled')
      $lineUndisabled.removeAttr('disabled')
      return
    } else {
      $subLineAmountInputs.attr('disabled', true)
      $lineFullAmount.attr('disabled', true)
      $lineUndisabled.attr('disabled', true)
      $lineExpireDate.removeAttr('readonly')
      $lineExpireDate.attr('disabled', 'disabled');
    }

    $subLineAmountInputs.each(index => {
      const $element = $($subLineAmountInputs[index])
      const currentValue = $element.val()
      const previousValue = $element.data('previous-value')
      if (currentValue != previousValue) {
        $element.val(previousValue).attr('disabled', 'disabled').trigger('change')
      }
    })
    $lineFullAmount.val(lineFullAmountPreviousValue).trigger('change')
    $lineExpireDate.val(lineExpireDatePreviousValue).trigger('change')
  })

  $('body').on('change', '.sub-line-amount', function () {
    const amountInputId = $(this).data('amount-input')
    const $amountInput = $(amountInputId)
    const newValue = $(this).val().replaceAll('.', '').replaceAll(',', '.')
    $amountInput.val(newValue)

    calculateAndChangePercentage($(this))
  })

  $('body').on('keyup', '.sub-line-amount', function () {
    validateMaxValueExceeded($(this))
  })

  $('body').on('hidden.bs.modal', '#modal-line, #modallines', function () {
    $(this).remove()
  })

  $('body').on('click', '.line-form-confirm-btn', function () {
    const formId = $(this).data('submit-form')
    const $form = $(formId)
    let error = false
    $('.error').text('')
    $('.is-invalid').removeClass('is-invalid')
    validateSubLinesAmounts()


    if (!error) {
      $form.find(':submit').trigger('click')
    }

  })

  $('body').on('click', '.signature-form-confirm-btn', function () {
    const formId = $(this).data('submit-form')
    const $form = $(formId)
    let error = false
    $('.error').text('')
    $('.is-invalid').removeClass('is-invalid')

    $form.find(':submit').trigger('click')
  })

  $('body').on('click', '#export_lines, #export_documents', function() {

    let typeController = $(this).attr('id').split('_')[1];

    $('#' + typeController + '-filter').attr('data-remote', 'false')
    $('#format').val('xlsx').trigger('change')
    $('#' + typeController + '-filter').attr('data-remote', 'true')
    $('#format').val('js')
    $('#' + typeController + '-filter').find(':submit').removeAttr('disabled')

    $(".loader").fadeOut()
  });

  $('body').on('keyup', '#line_full_amount', function () {
    validateSubLinesAmounts()
  })


  function initializeAsyncSelect($element){
    const parentId = $element.data("parent")
    const $parent = $(parentId)
    const url = $element.data("url")
    const excludeField = $element.data("exclude-field")
    const excludeValue = $element.data("exclude-value")

    $element.select2({
      ajax: {
        url,
        dataType: 'json',
        method: 'GET',
        cache: false,
        delay: 400,
        data: function (params) {
          let req = { 
            name: params.term,
          }
          req = (excludeField != null && excludeValue != null) ? {...req, excludeField, excludeValue} : req
          return req
        },
        processResults: function (response) {
          if (response != 'null') {
            return {
              results: response
            };
          }
        }
      },
      language: 'es',
      dropdownParent: parentId ? $parent : null
    });
  }

  window.initializeAsyncSelect = initializeAsyncSelect

  $('.js-basic-single-async').each((_index, element) => initializeAsyncSelect($(element)))

  $('body').on('show.bs.modal', '#modallines', function(e){
    initializeAsyncSelect($(this).find('.js-basic-single-async'))
  })

  $('body').on('show.bs.modal', '#signature-modal', function(e){
    initializeAsyncSelect($(this).find('.js-basic-single-async'))
  })

  $('body').on('change', '#select-client-line', function () {
    $('.loader-box').removeClass('hide')
    $.ajax({
      url: '/charge_last_line_use',
      dataType: 'script',
      method: 'GET',
      data: { company_id: $(this).val() }
    })
  })

  $('body').on('change', '#select-client-signature', function () {
    $('.loader-box').removeClass('hide')
    $.ajax({
      url: '/data_company_signature',
      dataType: 'script',
      method: 'GET',
      data: { company_id: $(this).val() }
    })
  })

  // case can approve with alternative role
  if ($(".save-line-btn").hasClass("active-alternative-role")) {
    // hide the save btn
    $(".save-line-btn").addClass("d-none");
  }

  // case can approve with main role
  if ($(".save-line-btn").hasClass("active-main-role")) {
    // hide the alternative close btn
    $(".disabled-alternative-approval-button").addClass("d-none")
  }

  $('.main-role-tab').on('click', function () {
    console.log("clicked main tab")
    // check if can approve with main role
    if ($(".save-line-btn").hasClass("active-main-role")) {
      // hide the alternative close btn
      $(".disabled-alternative-approval-button").addClass("d-none");

      // show the save btn
      $(".save-line-btn").removeClass("d-none");
    }

    // check if can appove with alternative role
    if ($(".save-line-btn").hasClass("active-alternative-role")) {
      // hide the save line btn
      $(".save-line-btn").addClass("d-none");

      // show the main role close btn
      $(".disabled-approval-button").removeClass("d-none");
    }
  });

  $('.alternative-role-tab').on('click', function () {
    console.log("clicked alternative tab")

    // check if can approve with alternative role
    if ($(".save-line-btn").hasClass("active-alternative-role")) {
      // hide the alternative close btn
      $(".disabled-approval-button").addClass("d-none");

      // show the save btn
      $(".save-line-btn").removeClass("d-none");
    }

    // check if can appove with main role
    if ($(".save-line-btn").hasClass("active-main-role")) {
      // hide the main close btn
      $(".disabled-approval-button").addClass("d-none");

      // show the save btn
      $(".save-line-btn").removeClass("d-none");
    }
  });
})



function calculateAndChangePercentage($subLineAmountInput) {
  const totalAmount = getLineFullAmountNumber()
  const amount = $subLineAmountInput.val().replaceAll('.', '')
  const $percentage = $subLineAmountInput.parents('.sub-line-row').find('.percentage-line')
  if (amount && totalAmount) {
    const percentage = Math.round((amount * 100 / totalAmount) * 100) / 100
    $percentage.text(`${percentage} %`)
  } else {
    $percentage.text('--')
  }
}

function getLineFullAmountNumber() {
  return Number($('#line_full_amount').val().replaceAll('.', ''))
}

function validateMaxValueExceeded($subLineAmountInput) {
  const maxValue = getLineFullAmountNumber()
  const value = $subLineAmountInput.val().replaceAll('.', '')
  const parsedValue = value ? Number(value) : ''

  if (maxValue < parsedValue) {
    return $subLineAmountInput.addClass('is-invalid')
  }

  $subLineAmountInput.removeClass('is-invalid')
}

function validateSubLinesAmounts() {
  const inputs = $('.sub-line-amount');

  inputs.each(index => {
    const $input = $(inputs[index])
    validateMaxValueExceeded($input)
  })
}

window.validateSubLinesAmounts = validateSubLinesAmounts