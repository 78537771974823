$( document ).on('turbolinks:load', events)

function events(){
    
    $('body').on('click', '#edit-administrations', function () {
        if($('.buttons-edit-administrations').hasClass("d-none") ){
            $('.buttons-edit-administrations').removeClass("d-none");
            $(this).html("Finalizar");
        }else{
            $('.buttons-edit-administrations').addClass("d-none");
            $(this).html("Editar Administradores");
        }
    })

    $('body').on('click', '.resend-email', function(){
        $(this).toggleClass("down");
    })

}