$( document ).on('turbolinks:load', common_events)
function common_events(){
  $('body').on('change', '#file_operations', function(){
    var submit = $(this).siblings('input[type="submit"]');
    submit.trigger('click');
  })

  $('body').on("click", ".show-invoice-operation", function(){
    let close_icon = $('#close-document-button > svg > path')
    close_icon.attr("fill",'#0055B8')
    let title = $(this).data('title') || 'Ver Archivo'
    let url = $(this).data('url')
    let $modal = $('#show-invoice')
    let url_archive = $(this).data('archive-url')
    if(!window.mobileAndTabletCheck()){
      $modal.find('#invoice-display-div').html($('<embed></embed>', {
        width: "100%", height: "100vh",
        src : url,
        id: "document-opener"
      }))
    } else {
      $modal.find('#invoice-display-div').html($('<iframe />', {
        width: "100%", height: "100vh",
        src : url,
        id: "document-opener"
      }))
    }
    $modal.modal('toggle');
    $modal.find('.modal-title').html(title)
    $modal.find('.link-download-archives').attr('data-url', url)
    if (url_archive){
      $modal.find('.loader-delete').removeClass('d-none').attr('href', url_archive)
    }
  });

  $('body').on('click', '.edit-inputs-in-row', function() {
    let target = $(this).data('target');
    $(target).find("input").each(function() {
      if ($(this).prop("disabled")) {
        $(this).prop("disabled", false);
      }
    });
  })

  function validateInputsForm(form, arrayRequire = []) {
    var requires = arrayRequire;

    var formData = new FormData(form);

    for (var i = 0; i < requires.length; i++) {
      if (!formData.has(requires[i])) {
        return false;
      }
    }
    return true;
  }

  async function sendForm(form, url) {
    try {
      let input = $(form).find('.form-control');
      let name = $(input).attr('name');
      let value = $(input).val();
      let last = $(input).data('last');

      if (value == last){
        return;
      }

      var fieldName = name.substring(name.indexOf('[') + 1, name.indexOf(']'));
      var objectName = name.substring(0, name.indexOf('['));
      var dataToSend = {};

      console.log(fieldName)
      console.log(objectName)
      if (!dataToSend[objectName]) {
        dataToSend[objectName] = {};
      }
      dataToSend[objectName][fieldName] = value;
      dataToSend['ajax'] = true;

      $('.loader').fadeIn('fast')
      $.ajax({
        url: url,
        dataType: 'script',
        method: 'put',
        data: dataToSend
      })
    } catch (error) {
      console.error('Error de red:', error);
    }
  }

  function sendFormsForAjax(target) {
    let tr = document.getElementById(target);
    let forms = tr.querySelectorAll('.form-operation');
    forms.forEach(function(form) {
      sendForm(form, form.action);
    });
  }

  $('body').on('click', '.btn-send-information-tax', function(){
    sendFormsForAjax($(this).data('target'))
  });

    $('body').on('change', '.selector-all', function() {
      let isChecked = $(this).is(':checked');
      let idTable = $(this).data('table');
      $(idTable + ' input[type="checkbox"]').prop('checked', isChecked);
    });

  $(document).on('click', '#generate-delivery-notes', function() {
    let dates = [];
    let urls = [];
    $('.body-table-generate-delivery-notes input[type="checkbox"]:checked').each(function() {
      let $this = $(this);
      let vehicleId = $this.data('id');
      let dateInput = $($this.data('date'));
      let date = dateInput.val();

      if (date) {
        let url = `/delivery_note_pdf/${vehicleId}.pdf`;
        urls.push(url);
      } else {
        dateInput.addClass('is-invalid');
        dates.push(vehicleId);
      }
    });

    if (dates.length > 0) {
      $modals.info({
        message: 'Por favor, ingrese la fecha de entrega para los siguientes vehículos ' + dates.join(', ')
      });
    }

    if (urls.length > 0) {
      let popup = window.open('', '_blank');
      if (popup) {
        urls.forEach(url => window.open(url, '_blank'));
        popup.close();
      } else {
        alert('Por favor, permita las ventanas emergentes para abrir las actas de entrega.');
      }
    }
  });
}